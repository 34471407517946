<script>
import BaseCheckbox from '@/Foundation/Components/BaseCheckbox.vue';
import CookieConsent from '../scripts/cookieconsent/CookieConsent';
import OneTrustCookieHandler from '@/Project/Common/code/src/scripts/lib/OneTrustCookieHandler';

export default {
    components: {
        BaseCheckbox
    },
    props: {
        gdprTitle: { type: String, default: null },
        gdprText: { type: String, default: null },
        requiredCookie: { type: String, default: null }
    },
    data() {
        return {
            component: null
        };
    },
    computed: {
        requiredCookieAccepted() {
            let oneTrustCookie = OneTrustCookieHandler.mapGdprCookieKeyToOneTrustCookieKey(this.requiredCookie);
            let cookiesState = this.$store.getters.getCookieValues;

            if (!cookiesState) {
                return false;
            }

            return (cookiesState[oneTrustCookie])
                ? true
                : false;
        }
    },
    created() {
        let setOneTrustOnConsentHandler = function (timeoutDelay) {
            if (!window.OneTrust) {
                setTimeout(function () {
                    setOneTrustOnConsentHandler(timeoutDelay * 1.1);

                }, timeoutDelay);

                return;
            }

            CookieConsent.initializeCookieState();

            window.OneTrust.OnConsentChanged((oneTrustResponse) => {
                let currentActiveCookies = oneTrustResponse.detail;
                let defaultState = OneTrustCookieHandler.getDefaultState();

                Object.keys(defaultState).forEach(function (key, index) {
                    defaultState[key] = currentActiveCookies.includes(key);
                });

                CookieConsent.storeCookies(defaultState);
            });
        };

        setOneTrustOnConsentHandler(1000);
    },
    methods: {
        acceptCookie() {
            const obj = {};
            obj[this.requiredCookie] = true;
            CookieConsent.storeCookies(obj);
        }
    }
};
</script>