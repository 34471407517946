<template>
    <div>
        <nav v-if="selected.solution !== undefined || selected.overview !== undefined" class="voc-breadcrumb">
            <div class="d-flex">
                <div class="voc-breadcrumb-btn__first">
                    <a
                        :href="getBaseUrl()"
                        class="
                            d-flex
                            voc-breadcrumb__btn voc-breadcrumb__btn--first
                            text-left
                            px-2 px-md-4
                            py-1 py-md-2
                            text-center
                        "
                        data-dm="voc-breadcrumb.button-to-landing"
                    >
                        <span class="font-weight-bold d-flex align-items-center">
                            <svg role="img" class="icon icon--undo">
                                <title>{{ dictionary.Reset }}</title>
                                <use
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    href="/assets/icons/common/ui.svg#undo"
                                />
                            </svg>
                        </span>
                    </a>
                </div>
                <div v-if="selected.segment" class="voc-breadcrumb-btn__second">
                    <button
                        :class="[isShortNavSegmentOpen ? 'is-open' : '']"
                        class="voc-breadcrumb__btn text-left pl-2 pr-4 pl-md-4 pr-md-5 py-1 py-md-2"
                        data-dm="voc-breadcrumb.button-show-segments"
                        @click="toggleShortNav('segment')"
                    >
                        <p class="voc-breadcrumb__category-title d-block mb-0">
                            {{ dictionary.SegmentName }}
                        </p>
                        <p class="voc-breadcrumb__category-title-value d-block mb-0 text-primary font-weight-bold">
                            {{ decodeURI(selected.segment) }}
                        </p>
                        <svg role="img" class="icon icon--chevron-down" aria-hidden="true">
                            <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                href="/assets/icons/common/ui.svg#chevron-down"
                            />
                        </svg>
                    </button>
                </div>
                <div v-if="selected.application" class="voc-breadcrumb-btn__third">
                    <button
                        :class="[isShortNavApplicationOpen ? 'is-open' : '']"
                        class="voc-breadcrumb__btn text-left pl-2 pr-4 pl-md-4 pr-md-5 py-1 py-md-2"
                        data-dm="voc-breadcrumb.button-show-applications"
                        @click="toggleShortNav('application')"
                    >
                        <p class="voc-breadcrumb__category-title d-block mb-0">
                            {{ dictionary.ApplicationName }}
                        </p>
                        <p class="voc-breadcrumb__category-title-value d-block mb-0 text-primary font-weight-bold">
                            {{ decodeURI(selected.application) }}
                        </p>
                        <svg role="img" class="icon icon--chevron-down" aria-hidden="true">
                            <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                href="/assets/icons/common/ui.svg#chevron-down"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </nav>

        <div v-if="selected.solution === undefined && selected.overview === undefined">
            <!-- intro title -->
            <div
                v-if="data.Title && selected.solution === undefined && selected.overview === undefined"
                class="bg-dark"
            >
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h2
                                :class="[subTitleVisible ? 'mt-5 mb-3' : 'mt-4 mt-md-5 mb-4 mb-md-5']"
                                class="text-white font-weight-bold"
                            >
                                {{ data.Title }}
                            </h2>
                        </div>
                    </div>
                </div>
            </div>

            <!-- select segment title -->
            <transition :duration="250" name="voc-slide">
                <div
                    v-if="
                        data.Segments.length !== 1 &&
                        selected.segment === undefined &&
                        selected.solution === undefined &&
                        selected.overview === undefined
                    "
                >
                    <div v-if="data.SegmentTitle" class="bg-dark">
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <h3 class="text-white mb-4 font-weight-normal">
                                        {{ data.SegmentTitle }}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>

            <!-- selected segment teaser -->
            <transition :duration="250" mode="out-in" name="voc-slide">
                <div
                    v-if="
                        selected.segment !== undefined &&
                        selected.solution === undefined &&
                        selected.overview === undefined
                    "
                    class="bg-dark voc-teaser voc-teaser--segment mb-3"
                >
                    <div
                        ref="teaser-segment"
                        class="hero-header hero-header--voc text-light"
                        data-dm="component.hero-header"
                    >
                        <button
                            v-if="data.Segments.length !== 1"
                            class="btn--reset"
                            data-dm="voc-teaser-segment.button-reset-state-segments"
                            @click="resetState($event, 'segment')"
                        >
                            <svg role="img" class="icon icon--close">
                                <title>{{ dictionary.ResetCategory }}</title>
                                <use
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    href="/assets/icons/common/ui.svg#close"
                                />
                            </svg>
                        </button>
                        <div class="hero-header__visual clearfix">
                            <div
                                :style="{ 'background-image': 'url(' + data.Segments[selected.segmentId].Image + ')' }"
                                class="visual"
                            />
                            <div class="container hero-header__content text-center">
                                <div class="body">
                                    <h3
                                        v-if="data.Segments[selected.segmentId].Name"
                                        class="h1 hero-header__title px-5 mb-3 mb-md-4"
                                    >
                                        {{ data.Segments[selected.segmentId].Name }}
                                    </h3>
                                    <div>
                                        <a
                                            v-if="data.Segments[selected.segmentId].LinkText"
                                            :href="data.Segments[selected.segmentId].Url"
                                            class="btn btn-primary mb-4 mb-sm-5"
                                            data-dm="voc-teaser-segments.link-overview"
                                        >
                                            {{ data.Segments[selected.segmentId].LinkText }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>

            <!-- slider segments -->
            <transition :duration="250" name="voc-fade">
                <div v-if="data.Segments.length !== 1 && selected.segment === undefined" class="bg-dark">
                    <div ref="slider-segments" class="voc-slider voc-slider--segments glide">
                        <div class="glide__track" data-glide-el="track">
                            <ul class="glide__slides">
                                <li
                                    v-for="(segment, index) in data.Segments"
                                    :key="index"
                                    class="glide__slide position-relative"
                                    tabindex="0"
                                    role="button"
                                    data-next-section="segment-teaser"
                                    data-category="segment"
                                    data-dm="voc-slider-segments.anchor-item"
                                    @click="glideHandleClick(index, $event, segment)"
                                    @keypress="glideHandleClick(index, $event, segment)"
                                >
                                    <img
                                        v-if="segment.Image"
                                        :src="segment.Image"
                                        :alt="segment.ImageAlt"
                                        class="img-fluid"
                                    />
                                    <h3 class="voc-slider__title text-white font-weight-bold mb-0">
                                        {{ segment.Name }}
                                    </h3>
                                </li>
                            </ul>
                        </div>
                        <div class="glide__arrows" data-glide-el="controls">
                            <button
                                class="glide__arrow glide__arrow--back"
                                data-glide-dir="<"
                                data-dm="voc-slider-segments.button-previous"
                            >
                                <svg role="img" class="icon icon--arrow-back">
                                    <use
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        href="/assets/icons/common/ui.svg#arrow-back"
                                    />
                                </svg>
                            </button>
                            <button
                                class="glide__arrow glide__arrow--next"
                                data-glide-dir=">"
                                data-dm="voc-slider-segments.button-next"
                            >
                                <svg role="img" class="icon icon--arrow-forward">
                                    <use
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        href="/assets/icons/common/ui.svg#arrow-forward"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </transition>

            <!-- select application title -->
            <transition :duration="250" name="voc-slide">
                <div v-if="selected.segment !== undefined && selected.application === undefined">
                    <div v-if="data.SegmentTitle" class="bg-dark">
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <h3 class="text-white mt-1 mb-4 font-weight-normal">
                                        {{ data.ApplicationTitle }}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>

            <!-- slider applications -->
            <transition :duration="250" name="voc-slide">
                <div
                    v-if="
                        (selected.segment !== undefined && selected.application === undefined) ||
                        (data.Segments.length === 1 &&
                            selected.segment !== undefined &&
                            selected.application === undefined)
                    "
                >
                    <div v-if="selectedSegment.Name" class="bg-dark">
                        <div ref="slider-applications" class="voc-slider voc-slider--applications glide">
                            <div class="glide__track" data-glide-el="track">
                                <ul class="glide__slides">
                                    <li
                                        v-for="(application, index) in selectedSegment.Applications"
                                        :key="index"
                                        class="glide__slide position-relative"
                                        tabindex="0"
                                        role="button"
                                        data-next-section="application-teaser"
                                        data-category="application"
                                        data-dm="voc-slider-applications.anchor-item"
                                        @click="glideHandleClick(index, $event, application)"
                                        @keypress="glideHandleClick(index, $event, application)"
                                    >
                                        <img
                                            v-if="application.Image"
                                            :src="application.Image"
                                            :alt="application.ImageAlt"
                                            class="img-fluid"
                                        />
                                        <h3
                                            v-if="application.Name"
                                            class="voc-slider__title text-white font-weight-bold mb-0"
                                        >
                                            {{ application.Name }}
                                        </h3>
                                    </li>
                                </ul>
                            </div>
                            <div class="glide__arrows" data-glide-el="controls">
                                <button
                                    class="glide__arrow glide__arrow--back"
                                    data-glide-dir="<"
                                    data-dm="voc-slider-applications.button-previous"
                                >
                                    <svg role="img" class="icon icon--arrow-back">
                                        <use
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            href="/assets/icons/common/ui.svg#arrow-back"
                                        />
                                    </svg>
                                </button>
                                <button
                                    class="glide__arrow glide__arrow--next"
                                    data-glide-dir=">"
                                    data-dm="voc-slider-applications.button-next"
                                >
                                    <svg role="img" class="icon icon--arrow-forward">
                                        <use
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            href="/assets/icons/common/ui.svg#arrow-forward"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>

            <!-- selected applications teaser -->
            <transition :duration="250" name="voc-slide">
                <div
                    v-if="selected.application !== undefined && selected.solution === undefined"
                    ref="teaser-application"
                    class="bg-dark voc-teaser voc-teaser--application mb-3"
                >
                    <div
                        class="hero-header hero-header--voc text-light"
                        data-dm="component.hero-header"
                        role="hero-header"
                    >
                        <button
                            class="btn--reset"
                            data-dm="voc-applications-teaser.button-reset-applications"
                            @click="resetState($event, 'application')"
                        >
                            <svg role="img" class="icon icon--close">
                                <title>{{ dictionary.ResetCategory }}</title>
                                <use
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    href="/assets/icons/common/ui.svg#close"
                                />
                            </svg>
                        </button>
                        <div class="hero-header__visual clearfix">
                            <div
                                :style="{ 'background-image': 'url(' + selectedApplication.Image + ')' }"
                                class="visual"
                            />
                            <div class="container hero-header__content text-center">
                                <div class="body">
                                    <h3 v-if="selectedApplication.Name" class="h1 hero-header__title px-5 mb-2 mb-md-5">
                                        {{ selectedApplication.Name }}
                                    </h3>
                                    <div>
                                        <a
                                            v-if="selectedApplication.LinkText"
                                            :href="selectedApplication.Url"
                                            class="btn btn-primary mb-4 mb-sm-5"
                                            data-dm="voc-teaser-applications.link-overview"
                                        >
                                            {{ selectedApplication.LinkText }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>

            <!-- select solution title -->
            <transition :duration="250" name="voc-slide">
                <div v-if="selected.segment !== undefined && selected.application !== undefined">
                    <div v-if="data.SolutionTitle" class="bg-dark">
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <h3 class="text-white my-4 font-weight-normal">
                                        {{ data.SolutionTitle }}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>

            <!-- slider solutions -->
            <transition :duration="250" name="voc-fade">
                <div v-if="selected.application !== undefined && selected.solution === undefined">
                    <div v-if="selectedApplication.Name" class="bg-dark">
                        <div ref="slider-solutions" class="voc-slider voc-slider--solutions glide">
                            <div class="glide__track" data-glide-el="track">
                                <ul class="glide__slides">
                                    <li
                                        v-for="(solution, index) in selectedApplication.Solutions"
                                        :key="index + 1"
                                        class="glide__slide position-relative text-center"
                                    >
                                        <img
                                            v-if="solution.Image"
                                            :src="solution.Image"
                                            :alt="solution.ImageAlt"
                                            class="img-fluid"
                                        />
                                        <h4
                                            v-if="solution.Name"
                                            class="
                                                voc-slider__content
                                                text-center text-white
                                                font-weight-bold
                                                mb-0
                                                mt-2
                                            "
                                        >
                                            {{ solution.Name }}
                                        </h4>
                                        <a
                                            v-if="solution.Url"
                                            :href="solution.Url"
                                            class="voc-slider__link"
                                            data-dm="voc-slider-solutions.link-item"
                                        >
                                            <span>
                                                {{ solution.Name }}
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="glide__arrows" data-glide-el="controls">
                                <button
                                    class="glide__arrow glide__arrow--back"
                                    data-glide-dir="<"
                                    data-dm="voc-slider-solutions.button-previous"
                                >
                                    <svg role="img" class="icon icon--arrow-back">
                                        <use
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            href="/assets/icons/common/ui.svg#arrow-back"
                                        />
                                    </svg>
                                </button>
                                <button
                                    class="glide__arrow glide__arrow--next"
                                    data-glide-dir=">"
                                    data-dm="voc-slider-solutions.button-next"
                                >
                                    <svg role="img" class="icon icon--arrow-forward">
                                        <use
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            href="/assets/icons/common/ui.svg#arrow-forward"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>

        <!-- slider segments solution page -->
        <transition :duration="250" name="voc-slide">
            <div v-if="isShortNavSegmentOpen" key="segment">
                <div :class="[isShortNavSegmentOpen ? '' : 'd-none']" class="bg-dark">
                    <div ref="slider-segments" class="voc-slider voc-slider--segments glide">
                        <div class="glide__track" data-glide-el="track">
                            <ul class="glide__slides">
                                <li
                                    v-for="(segment, index) in data.Segments"
                                    :key="index"
                                    class="glide__slide position-relative"
                                    data-next-section="segment-teaser"
                                    data-category="segment"
                                >
                                    <img
                                        v-if="segment.Image"
                                        :src="segment.Image"
                                        :alt="segment.ImageAlt"
                                        class="img-fluid"
                                    />
                                    <h3
                                        v-if="segment.Name"
                                        :class="
                                            selected.segment.toLowerCase() === segment.Name.toLowerCase()
                                                ? 'is-current'
                                                : ''
                                        "
                                        class="voc-slider__title text-white font-weight-bold mb-0"
                                    >
                                        {{ segment.Name }}
                                    </h3>
                                    <a
                                        v-if="segment.Name"
                                        :href="
                                            getBaseUrl() +
                                            '?segment=' +
                                            encodeURI(segment.SeoFriendlyQueryString).toLowerCase()
                                        "
                                        class="voc-slider__link"
                                        data-dm="voc-slider-overview-segments.link-item"
                                    >
                                        <span>
                                            {{ segment.Name }}
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="glide__arrows" data-glide-el="controls">
                            <button
                                class="glide__arrow glide__arrow--back"
                                data-glide-dir="<"
                                data-dm="voc-slider-overview-segments.button-previous"
                            >
                                <svg role="img" class="icon icon--arrow-back">
                                    <use
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        href="/assets/icons/common/ui.svg#arrow-back"
                                    />
                                </svg>
                            </button>
                            <button
                                class="glide__arrow glide__arrow--next"
                                data-glide-dir=">"
                                data-dm="voc-slider-overview-segments.button-next"
                            >
                                <svg role="img" class="icon icon--arrow-forward">
                                    <use
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        href="/assets/icons/common/ui.svg#arrow-forward"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- slider applications detail page -->
            <div v-if="isShortNavApplicationOpen" key="application">
                <div v-if="selectedSegment.Name" :class="[isShortNavApplicationOpen ? '' : 'd-none']" class="bg-dark">
                    <div ref="slider-applications" class="voc-slider voc-slider--applications glide">
                        <div class="glide__track" data-glide-el="track">
                            <ul class="glide__slides">
                                <li
                                    v-for="(application, index) in selectedSegment.Applications"
                                    :key="index"
                                    class="glide__slide position-relative"
                                    data-next-section="application-teaser"
                                    data-category="application"
                                >
                                    <img
                                        v-if="application.Image"
                                        :src="application.Image"
                                        :alt="application.ImageAlt"
                                        class="img-fluid"
                                    />
                                    <h3
                                        v-if="application.Name"
                                        :class="
                                            selected.application.toLowerCase() === application.Name.toLowerCase()
                                                ? 'is-current'
                                                : ''
                                        "
                                        class="voc-slider__title text-white font-weight-bold mb-0"
                                    >
                                        {{ application.Name }}
                                    </h3>
                                    <a
                                        v-if="application.Name"
                                        :href="
                                            getBaseUrl() +
                                            '?segment=' +
                                            encodeURI(selectedSegment.SeoFriendlyQueryString).toLowerCase() +
                                            '&application=' +
                                            encodeURI(application.SeoFriendlyQueryString).toLowerCase()
                                        "
                                        class="voc-slider__link"
                                        data-dm="voc-slider-overview-applications.link-item"
                                    >
                                        <span>
                                            {{ application.Name }}
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="glide__arrows" data-glide-el="controls">
                            <button
                                class="glide__arrow glide__arrow--back"
                                data-glide-dir="<"
                                data-dm="voc-slider-overview-applications.button-previous"
                            >
                                <svg role="img" class="icon icon--arrow-back">
                                    <use
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        href="/assets/icons/common/ui.svg#arrow-back"
                                    />
                                </svg>
                            </button>
                            <button
                                class="glide__arrow glide__arrow--next"
                                data-glide-dir=">"
                                data-dm="voc-slider-overview-applications.button-next"
                            >
                                <svg role="img" class="icon icon--arrow-forward">
                                    <use
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        href="/assets/icons/common/ui.svg#arrow-forward"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import Glide from '@glidejs/glide';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

export default {
    props: {
        navtree: {
            type: Object,
            required: true,
            default() {
                return {};
            }
        },
        dictionary: {
            type: Object,
            required: true,
            default() {
                return {};
            }
        }
    },

    data() {
        return {
            data: this.navtree,
            isShortNavSegmentOpen: false,
            isShortNavApplicationOpen: false,
            showApplicationSlider: false,
            selected: {
                segment: undefined,
                segmentId: undefined,
                application: undefined,
                applicationId: undefined,
                solution: undefined,
                overview: undefined,
            },
        };
    },

    computed: {
        /**
       * returns selected Object [segment]
       * @returns {object} selected category
       */
        selectedSegment() {
        // scenario: when user loads from predefined url or bookmark
            if (this.navtree.Segments.length === 1) {
                return this.navtree.Segments[0];
            }

            if ((this.selected.segment !== undefined) && (this.navtree.Segments.length !== 1)) {
                let segmentData = this.navtree.Segments.find((segment) => {
                    return encodeURI(segment.SeoFriendlyQueryString).toLowerCase() === this.selected.segment.toLowerCase();
                });

                return segmentData;
            }
            return {};
        },

        /**
       * returns selected Object [application]
       * @returns {object} selected category
       */
        selectedApplication() {
            if (this.selected.segment !== undefined && this.selected.application !== undefined) {

                let applicationData = this.selectedSegment.Applications.find((application) => {
                    return encodeURI(application.SeoFriendlyQueryString).toLowerCase() === this.selected.application.toLowerCase();
                });

                return applicationData;
            }
            return {};
        },

        /**
       * check if subtitle must be shown
       * @returns {boolean}
       */
        subTitleVisible() {
            if (this.selected.segment === undefined && this.selected.application === undefined && this.navtree.Segments.length === 1) {
                return false;
            }
            if (this.selected.segment !== undefined || this.selected.application !== undefined) {
                return false;
            }
            return true;
        }
    },

    mounted() {
        this.paramsRead();
        this.isDetailPage();
        this.initialSegment();
        this.glideInit();
    },

    methods: {
        /**
       * get index from nested object
       * @param {string} str matching string
       * @returns {number} index of selected category
       */
        getIndexFromObj(str) {
            let newIndex;

            this.navtree.Segments.forEach((segment, index) => {
                if (segment.SeoFriendlyQueryString.toLowerCase() === str) {
                    newIndex = index;
                }
                segment.Applications.forEach((application, index) => {
                    if (application.SeoFriendlyQueryString.toLowerCase() === str) {
                        newIndex = index;
                    }
                    application.Solutions.forEach((solution, index) => {
                        if (solution.Url.toLowerCase() === str) {
                            newIndex = index;
                        }
                    });
                });
            });

            return newIndex;
        },

        /**
       * toggle nav visibility on solution and overview pages
       * @param {string} target element to target
       * @returns {undefined}
       */
        toggleShortNav(target) {
            if (target === 'segment') {
                this.isShortNavSegmentOpen = !this.isShortNavSegmentOpen;
                this.isShortNavApplicationOpen = false;
            }
            if (target === 'application') {
                this.isShortNavApplicationOpen = !this.isShortNavApplicationOpen;
                this.isShortNavSegmentOpen = false;
            }
            this.glideInit();
        },

        /**
       * trigger event on click
       * @param {int} id index of element
       * @param {object} event $event
       * @param {object} obj object send
       * @returns {undefined}
       */
        glideHandleClick(id, event, obj) {
            if (!this.selected.solution || !this.selected.overview) {
                let currentCategory = event.currentTarget.getAttribute('data-category');

                if (currentCategory === 'segment') {
                    this.selected.segment = encodeURI(obj.SeoFriendlyQueryString);
                    this.selected.segmentId = id;
                    setTimeout(() => this.scrollToPosition('.voc-teaser--segment'), 400);
                }

                if (currentCategory === 'application') {
                    this.selected.application = encodeURI(obj.SeoFriendlyQueryString);
                    this.selected.applicationId = id;
                    setTimeout(() => this.scrollToPosition('.voc-teaser--application'), 400);
                }

                this.glideInit();
                this.paramsSave(currentCategory, obj);
            }
        },

        /**
       * scroll to element on page
       * @param {string} selector classname or ID to pass
       */
        scrollToPosition(selector) {
            let scrollEL = document.querySelector(selector);
            let elementPosition = scrollEL.getBoundingClientRect().top;
            let scrollToPosition;

            if (window.pageYOffset > 0) {
                scrollToPosition = elementPosition + window.pageYOffset;
            } else {
                scrollToPosition = elementPosition;
            }

            window.scrollTo({
                top: scrollToPosition,
                behavior: 'smooth'
            });
        },

        /**
       * reset all to init state
       * @param {object} e event
       * @param {string} category current category
       * @returns {undefined}
       */
        resetState(e, category) {
            e.preventDefault();
            let url = window.location.origin + window.location.pathname;
            let query = window.location.search.substring(1);
            let params = query.split('&');
            let newUrl = url + '?' + params[0];

            if (category === 'segment') {
                this.selected.segment = undefined;
                this.selected.application = undefined;
                this.$nextTick(() => {
                    this.glideInit();
                    setTimeout(() => this.scrollToPosition('.header'), 400);
                });

                // reset url
                window.history.pushState('', '', url);
            }

            if (category === 'application') {
                this.selected.application = undefined;
                this.$nextTick(() => {
                    this.glideInit();
                    setTimeout(() => this.scrollToPosition('.voc-teaser--segment'), 400);
                });

                // reset url to first selected nav level
                window.history.pushState('', '', newUrl);
            }
        },

        /**
       * glideJS initializing
       */
        glideInit() {
            const options =  {
                type: 'slider',
                perView: 5,
                rewind: false,
                gap: 2,
                breakpoints: {
                    576: {
                        perView: 2,
                        peek: {
                            before: 0,
                            after: 50
                        }
                    },
                    768: {
                        perView: 3,
                        peek: {
                            before: 0,
                            after: 50
                        }
                    },
                }
            };

            const optionsSolutions = {
                type: 'slider',
                perView: 3,
                rewind: false,
                gap: 10,
                breakpoints: {
                    576: {
                        perView: 1,
                        peek: {
                            before: 0,
                            after: 150
                        }
                    },
                    768: {
                        perView: 3,
                        peek: {
                            before: 0,
                            after: 50
                        }
                    },
                }
            };

            this.$nextTick(() => {
                if (this.$refs['slider-segments']) {
                    new Glide(this.$refs['slider-segments'], options).mount();
                }

                if (this.$refs['slider-applications']) {
                    new Glide(this.$refs['slider-applications'], options).mount();
                }

                if (this.$refs['slider-solutions']) {
                    new Glide(this.$refs['slider-solutions'], optionsSolutions).mount();
                }
            });
        },

        /**
       * update url after clicks on navigation
       * @param {string} key
       * @param {object} obj
       */
        paramsSave(key, obj) {
        // let baseUrl = [location.protocol, '//', location.host, location.pathname].join('');
            let baseUrl = `${location.protocol}//${location.host}${location.pathname}`;
            let urlQueryString = document.location.search;
            let objName = encodeURI(obj.SeoFriendlyQueryString.toLowerCase());
            let newParam = `${key}=${objName}`;
            let params = `?${newParam}`;

            // if querystring exists build params
            if (urlQueryString) {
                // eslint-disable-next-line no-useless-escape
                let keyRegex = new RegExp('([\?&])' + key + '[^&]*');

                // If param exists already, update it
                if (urlQueryString.match(keyRegex) !== null) {
                    params = urlQueryString.replace(keyRegex, '$1' + newParam);
                } else { // Otherwise, add it to end of query string
                    params = `${urlQueryString}&${newParam}`;
                }
            }
            window.history.pushState({}, '', baseUrl + params);
        },

        /**
       * get parameters from url and set navlevel
       * @returns {undefined}
       */
        paramsRead() {
            let paramString = '';

            window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (param, key, value) => {
                let encodedValue = decodeURI(value);

                if (key === 'segment') {
                    this.selected.segment = value;
                    this.selected.segmentId = this.getIndexFromObj(encodedValue);
                    this.glideInit();
                }

                if (key === 'application') {
                    this.selected.application = value;
                    this.selected.applicationId = this.getIndexFromObj(encodedValue);
                    this.glideInit();
                }

                if (key === 'solution') {
                    this.selected.solution = value;
                    this.glideInit();
                }

                if (key === 'overview') {
                    this.selected.overview = value;
                    this.glideInit();
                }

                paramString = paramString + param;
            });
        },

        /**
       * check if overview page is active & check if there is only one segment and if so, set initial segment
       * @returns {undefined}
       */
        initialSegment() {
            if (this.selected.overview === undefined) {
                if (this.navtree.Segments.length === 1) {
                    this.selected.segment = this.navtree.Segments[0].Name;
                    this.selected.segmentId = 0;
                    this.showApplicationSlider = true;
                    this.paramsSave('segment', this.navtree.Segments[0]);
                }
            }
        },

        /**
       * check if visitor is on solution or overview page.
       * @returns {boolean}
       */
        isDetailPage() {
            let found = false;
            let sgmts = this.navtree.Segments;
            let url = window.location.href.toLowerCase();

            for (let s = 0; s < sgmts.length; s++) {
                for (let a = 0; a < sgmts[s].Applications.length; a++) {
                    for (let so = 0; so < sgmts[s].Applications[a].Solutions.length; so++) {
                        if (sgmts[s].Applications[a].Solutions[so].Url !== '' && url.endsWith(sgmts[s].Applications[a].Solutions[so].Url.toLowerCase())) {
                            found = true;
                            this.selected.solutionId = sgmts[s].Applications[a].Solutions[so].Id;
                            this.selected.solution = encodeURI(sgmts[s].Applications[a].Solutions[so].SeoFriendlyQueryString);

                            break;
                        }
                    }
                    if ((found) || (sgmts[s].Applications[a].Url !== '' && url.endsWith(sgmts[s].Applications[a].Url.toLowerCase()))) {
                        found = true;
                        this.selected.applicationId = sgmts[s].Applications[a].Id;
                        this.selected.application = encodeURI(sgmts[s].Applications[a].SeoFriendlyQueryString);
                        this.selected.overview = true;

                        break;
                    }
                }
                if ((found) || (sgmts[s].Url !== '' && url.endsWith(sgmts[s].Url.toLowerCase()))) {
                    found = true;
                    this.selected.segmentId = sgmts[s].Id;
                    this.selected.segment = encodeURI(sgmts[s].SeoFriendlyQueryString);
                    this.selected.overview = true;

                    break;
                }
            }
            return found;
        },

        /**
       * get base url from origin and set prop
       * @returns {undefined}
       */
        getBaseUrl() {
            let baseUrl = window.location.origin;
            let path = this.navtree.LandingPageUrl;
            let url = baseUrl + path;

            return url;
        },

    },

};
</script>
