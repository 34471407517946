<template>
    <div
        v-if="!isHidden"
        class="cookieconsentcontainer"
        :class="[fixedOverlay && 'cookieconsentcontainer--fixed-overlay']"
        data-dm="component.cookieconsentcontainer"
    >
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2 class="cookieconsentcontainer__title text-center">
                        {{ title }}
                    </h2>
                    <slot />
                    <div class="cookieconsentcontainer__buttoncontainer">
                        <base-button
                            css-class="btn btn-primary"
                            :label="acceptAll ? acceptAllLabel : acceptLabel"
                            :click-handler="acceptCookies"
                        />
                        <base-button
                            css-class="btn btn-link"
                            :label="toggleContainerLabel"
                            :click-handler="toggleContainer"
                        />
                    </div>
                    <div
                        class="cookieconsentcontainer__collapse"
                        :class="[isOpen && 'cookieconsentcontainer__collapse--is-open']"
                    >
                        <hr />
                        <div class="cookieconsentcontainer__checkboxes col-md-6 offset-md-3">
                            <span v-for="(checkbox, index) in checkboxes" :key="index">
                                <p :key="index">{{ checkbox.label }}</p>
                                <base-checkbox
                                    :id="checkbox.id"
                                    :key="index"
                                    :name="checkbox.name"
                                    :value="checkbox.value"
                                    :checked="checkbox.checked"
                                    :disabled="checkbox.disabled"
                                    autocomplete="off"
                                    :checkbox-clicked="() => toggleCheckbox(checkbox, index)"
                                />
                            </span>
                        </div>
                        <hr />
                        <a :href="moreInfoLink">{{ moreInfoText }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseButton from '@/Foundation/Components/BaseButton.vue';
import BaseCheckbox from '@/Foundation/Components/BaseCheckbox.vue';
import CookieConsent from '../scripts/cookieconsent/CookieConsent';
import GDPRHandler from '@/Project/Common/code/src/scripts/lib/GDPRHandler';

export default {
    components: {
        BaseButton,
        BaseCheckbox
    },
    props: {
        title: { type: String, default: null },
        acceptAllLabel: { type: String, default: null },
        acceptLabel: { type: String, default: null },
        gdprCookies: { type: Array, default: null },
        moreInfoLink: { type: String, default: '#' },
        toggleContainerLabel: { type: String, default: null },
        moreInfoText: { type: String, default: null },
        fixedOverlay: { type: Boolean, default: false }
    },
    data() {
        return {
            checkboxes: [],
            isOpen: false,
            isHidden: false,
            acceptAll: true,
            concentIsGiven: false
        };
    },
    created() {
        GDPRHandler.updateGDPRCookie(this.gdprCookies);
        this.consentIsGiven = CookieConsent.getConsentGivenCookie();
        this.gdprCookies.forEach(item => {
            const randomVar = this.generateRandomVar();
            const alreadyAccepted = this.$store.getters.getCookieValues[item.value];
            return this.checkboxes.push({
                name: `${item.value}-cookie-${randomVar}`,
                value: `${item.value}`,
                label: `${item.label}`,
                id: `${item.value}-cookie-${randomVar}`,
                ...(item.checked && { checked: `${item.checked}` }),
                ...(alreadyAccepted && this.consentIsGiven && { checked: `${alreadyAccepted}` }),
                ...(item.disabled && { disabled: `${item.disabled}` })
            });
        });
    },
    beforeMount() {
        if (this.fixedOverlay) this.isHidden = this.consentIsGiven;
    },
    methods: {
        acceptCookies() {
            const data = this.acceptAll
                ? this.setActiveAllValues(this.checkboxes)
                : this.checkboxes;
            const values = CookieConsent.mapCookieValues(data);
            this.hideContainer();
            this.toggleContainer();
            CookieConsent.setConsentGivenCookie();
            return CookieConsent.storeCookies(values);
        },
        generateRandomVar() {
            return Math.random()
                .toString(36)
                .substring(7);
        },
        setActiveAllValues(arr) {
            const array = arr;
            array.forEach(item => {
                item.checked = true;
            });
            return array;
        },
        toggleContainer() {
            this.isOpen = !this.isOpen;
            this.acceptAll ? (this.acceptAll = false) : null;
        },
        hideContainer() {
            return this.fixedOverlay
                ? (this.isHidden = true)
                : (this.isHidden = false);
        },
        toggleCheckbox(checkbox, index) {
            return (this.checkboxes[index].checked = !checkbox.checked);
        }
    }
};
</script>
