/* global H */
import lodash from 'lodash';
import AsyncLoader from 'AsyncLoader';
import Maps from './Maps.js';

/**
 * Loads HERE maps api and functionality.
 *
 * @requires lodash
 * @requires module:project/Common.AsyncLoader
 * @extends module:project/Maps.Maps
 * @memberof module:project/Maps
 * @version 1.0.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class HereMaps extends Maps {
    /**
     * Upgrades DOM element and sets vars.
     * @param {jQueryElement} $element DOM Element to be upgraded.
     * @param {object} options Configuration options.
     */
    constructor($element, options) {
        // defaults
        let config = {
            appId: 'Ch87ydlyXjsmnI5AcXho',
            appCode: 'z2HsXPK48vhNPwyfcFr5ew',
            appLanguage: 'nl',
            appRegion: 'nl',
            appTileLanguage: 'dut',
            map: {
                center: { lat: 51.43328, lng: 5.50428 },
                zoom: 12,
            },
        };

        super($element, $.extend(true, config, options));

        // maps
        this.apiUrls = [
            'https://js.api.here.com/v3/3.0/mapsjs-core.js',
            'https://js.api.here.com/v3/3.0/mapsjs-service.js',
            //'https://js.api.here.com/v3/3.0/mapsjs-pano.js',
            'https://js.api.here.com/v3/3.0/mapsjs-ui.js',
            'https://js.api.here.com/v3/3.0/mapsjs-ui.css',
            'https://js.api.here.com/v3/3.0/mapsjs-mapevents.js',
            'https://js.api.here.com/v3/3.0/mapsjs-clustering.js',
        ];

        this.platform = null;
    }

    /**
     * Initialize.
     */
    init() {
        // only init if not initialized yet
        if (!this.initialized) {
            super.init();
        }
    }

    /**
     * Load Here Maps API async via the AsyncLoader.
     * @returns {promise} Promise with H object.
     */
    loadApi() {
        return new Promise((resolve) => {
            AsyncLoader.load(this.apiUrls, () => {
                resolve(H);
            });
        });
    }

    /**
     * Initializes Map on element ID.
     * @param {DOMElement} $mapElement DOM element to add map to.
     * @param {object} opts Options to override default options.
     * @param {object} events Add events to map.
     */
    initMap($mapElement, opts, events) {
        // set platform
        this.platform = new H.service.Platform({
            app_id: this.config.appId,
            app_code: this.config.appCode,
            useCIT: false,
            useHTTPS: true,
        });

        // options
        const options = $.extend(
            {
                center: this.config.map.center,
                zoom: this.config.map.zoom,
                zoomControl: this.config.map.zoomControl,
                mapTypeControl: this.config.map.mapTypeControl,
                scaleControl: this.config.map.scaleControl,
                streetViewControl: this.config.map.streetViewControl,
            },
            opts
        );

        // Obtain the default map types from the platform object
        const defaultLayers = this.platform.createDefaultLayers();

        // Instantiate (and display) a map object:
        this.map = new H.Map($mapElement, defaultLayers.normal.map, options);

        // set UI localization
        const uiLocalization = this.setUiLocale(this.config.appLanguage, this.config.appRegion);

        // Create the default UI and localisation:
        this.ui = H.ui.UI.createDefault(this.map, defaultLayers, uiLocalization);
        this.ui.getControl('mapsettings').setVisibility(options.mapTypeControl);
        this.ui.getControl('zoom').setVisibility(options.zoomControl);
        this.ui.getControl('scalebar').setVisibility(options.scaleControl);
        //this.ui.getControl('panorama').setVisibility(options.streetViewControl);

        // create base tile layer
        const mapTileService = this.platform.getMapTileService({
            type: 'base',
        });

        // localised map tiles based on base layer
        this.map.setBaseLayer(
            mapTileService.createTileLayer('maptile', 'normal.day', 256, 'png8', {
                lg: this.config.appTileLanguage.toUpperCase(),
            })
        );

        // Add map events functionality to the map
        const mapEvents = new H.mapevents.MapEvents(this.map);

        // Add behavior to the map: panning, zooming, dragging.
        const behavior = new H.mapevents.Behavior(mapEvents);
        behavior.disable(H.mapevents.Behavior.WHEELZOOM);

        // events
        this.map.addEventListener('mapviewchange', this.handleMapReady.bind(this, events));
        $(window)
            .off('resize.hmaps')
            .on(
                'resize.hmaps',
                lodash.debounce(() => {
                    if (typeof events.onResize === 'function') {
                        events.onResize();
                    }
                }, 200)
            );

        return this.map;
    }

    onReady() {
        super.onReady();
    }

    /**
     * Returns Geolocation data and bounding boxes of countries and/or cities.
     * @param {string} country ISO 3 country string.
     * @param {string} city Name of city.
     * @returns {promise} Data object.
     */
    getGeoLocation(country, city = false, postalCode = false) {
        return new Promise((resolve, reject) => {
            let returnObject = {};
            let data = {
                app_id: this.config.appId,
                app_code: this.config.appCode,
            };
            if (city) {
                if (country) {
                    data.country = country;
                    data.city = city;
                } else {
                    data.city = city;
                }
            } else {
                data.country = country;
            }
            if (postalCode) {
                data.postalcode = postalCode;
            }
            $.ajax({
                type: 'GET',
                url: '//geocoder.api.here.com/6.2/geocode.json',
                data: data,
                contentType: '',
            }).then((response) => {
                if (response) {
                    const resp = response.Response.View;
                    if (resp.length > 0) {
                        const location = resp[0].Result[0].Location;
                        returnObject.lat = location.DisplayPosition.Latitude;
                        returnObject.lng = location.DisplayPosition.Longitude;
                        returnObject.topLeft = {
                            lat: location.MapView.TopLeft.Latitude,
                            lng: location.MapView.TopLeft.Longitude,
                        };
                        returnObject.bottomRight = {
                            lat: location.MapView.BottomRight.Latitude,
                            lng: location.MapView.BottomRight.Longitude,
                        };
                    }
                    resolve(returnObject);
                } else {
                    reject(returnObject);
                }
            });
        });
    }

    /**
     * Creates my location icon based on svg markup.
     */
    createMyLocationIcon() {
        return new H.map.Icon(this.myLocationSvgMarkup);
    }

    /**
     * Creates a my location marker with icon.
     * @param {object} latlng Location data.
     * @param {float} latlng.lat Latitude.
     * @param {float} latlng.lng Longitude.
     */
    createMyLocationMarker(latlng) {
        return new H.map.Marker(
            { lat: latlng.lat, lng: latlng.lng },
            { icon: this.createMyLocationIcon(), data: { name: 'locationmarker' } }
        );
    }

    /**
     * Creates marker icons based on svg markup.
     * @param {string} [label] Optional label on marker icon.
     */
    createMarkerIcon(label = '') {
        return new H.map.Icon(this.markerSvgMarkup.replace(/{{LABEL}}/, label));
    }

    /**
     * Creates my location icon based on svg markup.
     */
    createClusterIcon() {
        return new H.map.Icon(this.clusterSvgMarkup);
    }

    /**
     * Creates a marker with icon and optional label on icon.
     * @param {object} latlng Location data.
     * @param {float} latlng.lat Latitude.
     * @param {float} latlng.lng Longitude.
     * @param {string} [label] Optional label on marker icon.
     */
    createMarker(latlng, label = '') {
        return new H.map.Marker({ lat: latlng.lat, lng: latlng.lng }, { icon: this.createMarkerIcon(label) });
    }

    /**
     * Creates and returns a range circle object.
     * @returns {object} Range circle ready to be added to the map.
     */
    createRange(range) {
        return new H.map.Circle(this.getCalculatedCenter(), range * 1000, {
            style: {
                strokeColor: 'rgba(0, 82, 156, 0.1)',
                lineWidth: 1,
                fillColor: 'rgba(0, 82, 156, 0.1)',
            },
            data: {
                name: 'rangeCircle',
            },
        });
    }

    /**
     * Creates a map group to add objects to.
     * @returns {H.map.Group}
     */
    createMapGroup() {
        return new H.map.Group();
    }

    /**
     * Creates a Cluster data point.
     * @param {*} lat Latitude.
     * @param {*} lng Longitude.
     * @param {*} alt Altitude.
     * @param {*} data Data to store with point.
     * @return {object} Cluster data point.
     */
    createClusterDataPoint(lat, lng, alt, data) {
        return new H.clustering.DataPoint(lat, lng, alt, data);
    }

    createClusterProvider(dataPoints) {
        return new H.clustering.Provider(dataPoints, {
            clusteringOptions: {
                eps: 64, // Maximum radius of the neighborhood
                minWeight: 3, // minimum weight of points required to form a cluster
            },
            theme: {
                getClusterPresentation: (cluster) => {
                    // create the marker
                    const clusterMarker = new H.map.Marker(cluster.getPosition(), {
                        icon: this.createClusterIcon(),
                        // set min/max zoom with values from the cluster,
                        // otherwise clusters will be shown at all zoom levels:
                        min: cluster.getMinZoom(),
                        max: cluster.getMaxZoom(),
                    });
                    return clusterMarker;
                },
                getNoisePresentation: (noisePoint) => {
                    // get a reference to data object our noise points
                    const data = noisePoint.getData();
                    // create a marker for the noisePoint
                    const noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
                        // Use min zoom from a noise point
                        // to show it correctly at certain zoom levels:
                        min: noisePoint.getMinZoom(),
                        icon: this.createMarkerIcon(data.markerIndex),
                    });
                    noiseMarker.setData(data);
                    return noiseMarker;
                },
            },
        });
    }

    /**
     * Creates a layer.
     * @param {object} dataProvider
     */
    createLayer(dataProvider) {
        return new H.map.layer.ObjectLayer(dataProvider);
    }

    /**
     * Adds a layer to the current map.
     * @param {*} layer
     */
    addLayer(layer) {
        return this.map.addLayer(layer);
    }

    /**
     * Removes a layer to the current map.
     * @param {*} layer
     */
    removeLayer(layer) {
        this.map.removeLayer(layer);
    }

    /**
     * Adds objects to the map.
     * @param {object} obj
     */
    addObject(obj) {
        this.map.addObject(obj);
    }

    /**
     * Removes objects to the map.
     * @param {object} obj
     */
    removeObject(obj) {
        if (obj) {
            const name = obj.getData().name;
            for (const mapObj of this.map.getObjects()) {
                const data = mapObj.getData();
                if (typeof data !== 'undefined' && data.name === name) {
                    this.map.removeObject(mapObj);
                }
            }
        }
    }

    /**
     * Handles first map ready event.
     * @param {objects} [events] Defined events.
     */
    handleMapReady(events = {}) {
        if (!this.initialized) {
            this.initialized = true;
            if (typeof events.onReady === 'function') {
                events.onReady();
            }
        }
    }

    /**
     * Sets center of the map.
     * @param {object|string} loc Coordinates object or location.
     * @param {float} loc.lat Latitude.
     * @param {float} loc.lng Longitude.
     * @param {booleam} [animation=false] Optional animation. Defaults to false.
     * @param {booleam} [updateLast=true] Optional update last position. Defaults to true.
     */
    setCenter(loc, animation = false, updateLast = true) {
        if (updateLast === true) {
            this.last.map.center = this.getCenter();
        }
        this.map.setCenter(loc, animation);
        this.current.map.center = loc;
    }

    /**
     * Returns current center of the map.
     * @returns {object} Coordinates object.
     * @returns {object.lat} Latitude.
     * @returns {object.lng} Longitude.
     */
    getCenter() {
        return this.current.map.center;
    }

    /**
     * Returns center calculated by maps.
     */
    getCalculatedCenter() {
        return this.map.getCenter();
    }

    /**
     * Sets zoom level of the map.
     * @param {number} level Zoom level.
     * @param {boolean} [animation=false] Optional animation. Defaults to false.
     * @param {boolean} [updateLast=true] Optional update of last position. Defaults to true.
     */
    setZoom(level, animation = false, updateLast = true) {
        if (updateLast === true) {
            this.last.map.zoom = this.getZoom();
        }
        this.map.setZoom(level, animation);
        this.current.map.zoom = level;
    }

    /**
     * Gets zoom level of the map.
     * @returns {number} Current zoomlevel.
     */
    getZoom() {
        return this.map.getZoom();
    }

    /**
     * Sets zoom level by creating a bounding box of top, left, bottom, right coords.
     * @param {number} top
     * @param {number} left
     * @param {number} bottom
     * @param {number} right
     * @returns {object} Updated map object
     */
    setBoundingBox(top, left, bottom, right, animation = false) {
        const bbox = new H.geo.Rect(top, left, bottom, right);
        this.map = this.map.setViewBounds(bbox, animation);
    }

    /**
     * Sets zoom level by bounding box of an object.
     * @param {rect} bbox H.map.Rect object.
     * @returns {object} Updated map object
     */
    setBoundingBoxByRect(bbox, animation = false) {
        this.map = this.map.setViewBounds(bbox, animation);
    }

    /**
     * Resizes the map viewport.
     */
    resize() {
        this.map.getViewPort().resize();
    }

    /**
     * Sets UI locale.
     * @param {string} lang Language ISO code.
     * @param {string} region Country ISO code.
     * @returns {string} Found locale or default en-US.
     */
    setUiLocale(lang, region) {
        // first try configured locale
        let localization = lang.toLowerCase() + '-' + region.toUpperCase();
        if (H.ui.i18n.defaultLocales.indexOf(localization) === -1) {
            // try language based
            localization = lang.toLowerCase() + '-' + lang.toUpperCase();
            if (H.ui.i18n.defaultLocales.indexOf(localization) === -1) {
                // try region based
                localization = region.toLowerCase() + '-' + region.toUpperCase();
                if (H.ui.i18n.defaultLocales.indexOf(localization) === -1) {
                    // set default
                    localization = 'en-US';
                }
            }
        }
        return localization;
    }
}

// register to Component Handler
window.ComponentHandler.register({
    constructor: HereMaps,
    classAsString: 'HereMaps',
    cssClass: 'js-here-maps',
});

export default HereMaps;
