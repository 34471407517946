<template>
    <div class="truck-filter-overview">
        <slot name="introduction" />
        <!-- filters -->
        <div class="px-5 mb-md-2 has-divider-as-background">
            <form>
                <div class="row justify-content-center px-md-5">
                    <div
                        v-for="filter in filters"
                        :key="filter.id"
                        class="mb-3 mb-md-0"
                        :class="filterDropdownColumnClass"
                    >
                        <select
                            :id="filter.id"
                            ref="filterSelects"
                            v-model="selectedFilters[filter.id]"
                            :name="filter.id"
                            class="form-control"
                        >
                            <option value="">
                                {{ filter.displayName }}
                            </option>
                            <option v-for="facet in filter.facets" :key="facet.id" :value="facet.id">
                                {{ facet.value }}
                            </option>
                        </select>
                    </div>
                </div>
            </form>
        </div>
        <!-- number of results -->
        <div class="text-center my-4 mt-md-5">
            <span class="lead text-grey-500">{{ trucksResultLabel }}</span>
        </div>
        <!-- render overview client-side if filters are used -->
        <div v-if="hasAnyFilterSelected" class="row">
            <div v-for="truck in filteredTrucks" :key="truck.id" class="col-md-4 col-lg-3 py-md-3">
                <a
                    class="link-card text-center pt-4 pb-3"
                    :href="truck.href"
                    :target="truck.target"
                    :rel="truck.target && 'noreferrer noopener'"
                >
                    <img :src="truck.image" :alt="truck.displayName" loading="lazy" />
                    <p class="lead text-grey-800 font-weight-bold mt-2 mb-0">
                        {{ truck.displayName }}
                    </p>
                    <p class="text-grey-500">
                        {{ truck.subtitle }}
                    </p>
                    <div class="btn btn-round">
                        <span class="icon icon--chevron-right icon--right">
                            <svg role="img" title="icon: chevron-right">
                                <use href="/assets/icons/common/ui.svg#chevron-right"></use>
                            </svg>
                        </span>
                    </div>
                </a>
            </div>
        </div>
        <!-- else server-side render the overview  -->
        <slot v-else name="ssr" />
    </div>
</template>

<script>
export default {
    props: {
        trucks: {
            type: Array,
            default: () => [],
        },
        filters: {
            type: Array,
            default: () => [],
        },
        dictionary: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            selectedFilters: {}, // uses dynamic filter types (coming from filters prop) as keys
        };
    },
    computed: {
        filteredTrucks() {
            // filter trucks AND - therefore use .every()
            return this.trucks.filter((truck) => {
                return Object.entries(this.selectedFilters)
                    .every(([filterType, filterValue]) => {
                        if (!filterValue) {
                            // empty filter value should not impact AND filter
                            return true;
                        }
                        if (!truck.specs || !Array.isArray(truck.specs[filterType])) {
                            return false;
                        }
                        return truck.specs[filterType].includes(filterValue);
                    });
            });
        },
        /**
     * Checks if any selectedFilters property has a truthy value
     */
        hasAnyFilterSelected() {
            return Object.values(this.selectedFilters).some(Boolean);
        },
        filterDropdownColumnClass() {
            return this.filters.length <= 3 ? 'col-md-4' : 'col-md-3';
        },
        trucksResultLabel() {
            const numberOfResults = this.filteredTrucks.length;
            const numberOfResultsLabel = this.dictionary.numberOfResults.replace('{results}', numberOfResults);
            const noResultsLabel = this.dictionary.noResults;
            return numberOfResults > 0 ? numberOfResultsLabel : noResultsLabel;
        },
    },
    mounted() {
    // to make sure <select> refs are updated with cached values
        setTimeout(() => {
            this.useDefaultFilterSelectValues();
        }, 150);
    },
    methods: {
    /**
     * Some browsers cache the <select> value - therefore update
     * the selectedFilters object by using the filter <select> refs' name and value
     **/
        useDefaultFilterSelectValues() {
            // name is the id of the filter
            // value is the value of the <select> (defaults to empty string without cached value)
            this.$refs.filterSelects.forEach(({ name, value }) => {
                this.$set(this.selectedFilters, name, value);
            });
        },
    },
};
</script>