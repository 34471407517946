import CookieHandler from './CookieHandler.js';
import store from '@/Project/DAF/code/src/scripts/store.common.js';
import config from '@/Feature/CookieConsent/code/src/scripts/cookieconsent/CookieConsentConfig.js';

const GDPRHandler = {
    updateGDPRCookie(arr) {
        const array = arr;
        const val = CookieHandler.getCookie(config.cookiename);
        return val ? store.commit('setCookieValues', val) : this.setInitialCookie(array);
    },
    cookiesAreAccepted([...arr]) {
        let cookiesAreAccepted = true;
        const array = arr;
        const cookieObj = CookieHandler.getCookie(config.cookiename);
        array.map((item) => {
            const value = cookieObj[item];
            return value ? null : (cookiesAreAccepted = false);
        });
        return cookiesAreAccepted;
    },
    setInitialCookie(arr) {
        const array = arr;
        const defaultcookie = {};
        array.forEach((item) => {
            defaultcookie[item.value] = item.default;
        });
        return (
            CookieHandler.setCookie(config.cookiename, defaultcookie), store.commit('setCookieValues', defaultcookie)
        );
    },
};

export default GDPRHandler;
