import Vue from 'vue';
import Vuex from 'vuex';
import CookieStore from '@/Feature/CookieConsent/code/src/scripts/cookieconsent/CookieStore';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        cookieStore: CookieStore,
    },
});
