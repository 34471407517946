<template functional>
    <div class="base-gdpr-template">
        <div
            v-if="props.image"
            class="base-gdpr-template__background js-responsive-background"
            :style="[props.image && `background-image: url(${props.image});`]"
        >
            <base-image v-if="props.image" :src="props.image" css-class="bg d-none" alt="" />
            <div class="gradient" />
        </div>
        <div :class="props.cssClass">
            <div class="base-gdpr-template__inner-container col-md-8">
                <h2 class="base-gdpr-template__title text-center">
                    {{ props.gdprTitle }}
                </h2>
                <p class="base-gdpr-template__text text-center">
                    {{ props.gdprText }}
                </p>
                <base-checkbox
                    :id="`gdpr-checkbox-${props.id}`"
                    :name="`gdpr-checkbox-${props.id}`"
                    :value="`gdpr-checkbox-${props.id}`"
                    autocomplete="off"
                    :checkbox-clicked="props.checkboxClicked"
                />
            </div>
        </div>
    </div>
</template>

<script>
import BaseImage from '@/Foundation/Components/BaseImage.vue';

export default {
    components: {
        BaseImage
    }
};
</script>