<template>
    <HubspotForm
        :portal-id="portalId"
        :form-id="formId"
        :redirect-url="redirectUrl"
        :multi-step="multiStep"
        @ready="onFormReady"
        @submit="onFormSubmit"
    >
    </HubspotForm>
</template>

<script>
import lodash from 'lodash';
import AjaxHandler from 'AjaxHandler';
import HereMaps from '../../../../../Project/Common/code/src/scripts/maps/HereMaps';
import HubspotForm from './HubspotForm';
import { HubspotFormsHelper, HubspotPropertyType } from '../scripts/HubspotFormsHelper';
import { HubspotService } from '../scripts/HubspotService';

const ajax = new AjaxHandler();
const hereAPI = new HereMaps();

export default {
    components: { HubspotForm },
    props: {
        portalId: { type: String, required: true },
        formId: { type: String, required: true },
        redirectUrl: { type: String, default: undefined },
        multiStep: { type: Boolean, default: false },
        newDealerDropdown: { type: Boolean, default: false },
        fieldNames: { type: Object, default: () => ({}) },
        locale: { type: String, required: true },
        initialCountry: { type: Object, default: null },
        countries: { type: Array, default: () => [] },
        range: { type: String, required: true },
        dealerTypes: { type: String, default: '' },
        managerCodes: { type: String, default: '' },
        departmentCodes: { type: String, default: '' },
        dictionary: { type: Object, default: () => ({}) },
    },
    data() {
        return {
            $form: null,
            fields: {},
            selectedCountryCode: '',
            selectedZipCode: '',
            isZipCodeInvalid: false,
            selectedDealerCode: '',
            dealers: [],
            dealerCompanyProperties: [],
        };
    },
    computed: {
        countryCode() {
            return this.initialCountry ? this.initialCountry.CountryCode : this.selectedCountryCode;
        },
        managerCodesList() {
            return this.managerCodes ? this.managerCodes.toUpperCase().split(',') : [];
        },
        departmentCodesList() {
            return this.departmentCodes ? this.departmentCodes.toUpperCase().split(',') : [];
        },
    },
    watch: {
        selectedCountryCode(newValue) {
            const countryCode = newValue;
            const {
                selectedZipCode: zipCode,
                range,
                dealerTypes,
                managerCodesList: managerCodes,
                departmentCodesList: departmentCodes
            } = this;

            if (countryCode) {
                if (zipCode) {
                    this.populateDealersWithZipCode({ zipCode, countryCode, range, dealerTypes, managerCodes, departmentCodes });
                } else {
                    this.populateDealers({ countryCode, range, dealerTypes, managerCodes, departmentCodes });
                }
            }
        },
        selectedZipCode(newValue) {
            const zipCode = newValue;
            const { countryCode, range, dealerTypes, managerCodesList: managerCodes, departmentCodesList: departmentCodes } = this;

            if (zipCode) {
                this.populateDealersWithZipCode({ zipCode, countryCode, range, dealerTypes, managerCodes, departmentCodes });
            } else {
                // reset validation if empty value
                this.isZipCodeInvalid = false;
                this.populateDealers({ countryCode, range, dealerTypes, managerCodes, departmentCodes });
            }
        },
        selectedDealerCode(newValue) {
            const dealerCode = newValue;
            if (dealerCode) {
                const dealer = this.getDealerByCode(dealerCode);
                if (dealer !== null) {
                    // prepare custom hidden fields with ddpd data
                    this.removeHiddenPropertyFields();

                    // create hidden fields for name (contact and company), number (contact and company) and email (company)
                    this.addHiddenContactField({ name: 'dealer_company_name', value: dealer.Text, label: 'Dealer Company name' });

                    if (this.newDealerDropdown) {
                        this.addHiddenContactField({ name: 'dealer_company_name', value: dealer.Text, label: 'Dealer Company name' });
                        this.addHiddenContactField({ name: 'dealer_number', value: dealer.Value, label: 'Dealer Number' });
                        this.addHiddenContactField({ name: 'dealer_email', value: dealer.Email, label: 'Dealer Email' });
                    }
                    else {
                        this.addHiddenCompanyField({ name: 'dealer_company_name', value: dealer.Text, label: 'Dealer Company name' });
                        this.addHiddenCompanyField({ name: 'dealer_number', value: dealer.Value, label: 'Dealer Number' });
                        this.addHiddenCompanyField({ name: 'dealer_email', value: dealer.Email, label: 'Dealer Email' });
                    }

                    // create hidden fields for departments and managers
                    const personFields = [
                        ...this.getDepartmentFields(dealer.Departments),
                        ...this.getManagerFields(dealer.Managers)
                    ];

                    personFields.forEach((person) => {
                        if (this.newDealerDropdown) {
                            this.addHiddenContactField({ name: person.fieldName, value: person.email, label: person.fieldLabel });
                        } else {
                            this.addHiddenCompanyField({ name: person.fieldName, value: person.email, label: person.fieldLabel });
                        }
                    });

                    if (!this.newDealerDropdown) {
                        this.createMissingDealerCompanyProperties(personFields);
                    }
                }
            }
        },
        isZipCodeInvalid(newValue) {
            HubspotFormsHelper.validateField(this.fields.zipCode, !newValue, this.dictionary.zipCodeValidation);
        }
    },
    methods: {
        onFormSubmit() {
            // use $form here
        },
        /**
     * Used to determine when the form fields can be accessed
     */
        onFormReady($form) {
            this.$form = $form;
            this.storeFormFields();
            this.initCountryField();
            this.initZipCodeField();
            this.initDealerField();

            if (!this.newDealerDropdown) {
                HubspotService.getDealerCompanyPropertiesGroup(this.locale)
                    .then((group) => {
                        if (group && group.properties) {
                            this.dealerCompanyProperties = group.properties;
                        }
                    })
                    .catch((error) => {
                        console.error(error.message);
                    });
            }
        },
        /**
     * Contact property defined in HubSpot is used as [name] attribute on the form field
     * We store these form fields (dealer, country and zip code) based on the fieldNames prop passed in
     * @returns {Object} field name as key and jQuery object as value
     */
        storeFormFields() {
            Object.entries(this.fieldNames)
                .forEach(([key, name]) => {
                    const $field = this.$form.find(`[name="${name}"]`);
                    if ($field.length > 0) {
                        this.fields[key] = $field;
                    } else {
                        throw new Error(`${key} field with name "${name}" was not found`);
                    }
                });
        },
        initCountryField() {
            const $countrySelect = this.fields.country;
            // remove all options except for the first
            $countrySelect.find('option').not(':first').remove();

            if (this.initialCountry) {
                const { Country, CountryCode } = this.initialCountry;
                // add by default selected option with initial country name
                $countrySelect.append($('<option>', { selected: true, value: CountryCode, text: Country }));
                $countrySelect.prop('disabled', true);
            } else {
                // populate select with available countries
                this.countries.forEach(({ Country, CountryCode }) => {
                    $countrySelect.append($('<option>', { value: CountryCode, text: Country }));
                });

                $countrySelect.change(e => {
                    // will trigger watcher
                    this.selectedCountryCode = e.target.value;
                });
            }
        },
        initZipCodeField() {
            const $zipCodeInput = this.fields.zipCode;

            $zipCodeInput.blur(() => {
                // will trigger watcher
                this.selectedZipCode = $zipCodeInput.val();
            });
        },
        initDealerField() {
            const { countryCode, range, dealerTypes, managerCodesList: managerCodes, departmentCodesList: departmentCodes } = this;

            if (this.newDealerDropdown) {
                const $dealerField = this.fields.dealer;
                const $dealerSelect = $('<select>', { name: 'dealers', id: 'dealers' });
                $dealerSelect.append('<option>');
                this.fields.dealer = $dealerSelect;

                this.populateDealers({ dealerTypes, range, countryCode, managerCodes, departmentCodes });

                $dealerSelect.change((e) => {
                    // will trigger watcher
                    this.selectedDealerCode = e.target.value;
                    $dealerField.val(e.target.value);
                    $dealerField.trigger('change');
                });

                $dealerField.after($dealerSelect);
                $dealerField.parents(`.hs_${this.fieldNames['dealer']}`).show();
                $dealerField.hide();

                $dealerSelect.attr('class', $dealerField.attr('class'));
                const observer = new MutationObserver(function (mutations) {
                    mutations.forEach(function (mutation) {
                        $dealerSelect.attr('class', $(mutation.target).attr(mutation.attributeName));
                    });
                });
                observer.observe($dealerField[0], {
                    attributes: true,
                    attributeFilter: ['class']
                });
            }
            else {
                this.populateDealers({ dealerTypes, range, countryCode, managerCodes, departmentCodes });

                const $dealerSelect = this.fields.dealer;
                $dealerSelect.change((e) => {
                    // will trigger watcher
                    this.selectedDealerCode = e.target.value;
                });
            }
        },
        populateDealersWithZipCode({ zipCode, countryCode, ...rest }) {
            // no need to search zip code without country
            if (!countryCode) {
                this.isZipCodeInvalid = true;
                return;
            }

            this.getLatLng(zipCode, countryCode)
                .then(({ lat, lng }) => {
                    this.isZipCodeInvalid = false;
                    this.populateDealers({ latitude: lat, longitude: lng, countryCode, ...rest });
                })
                .catch(() => {
                    this.isZipCodeInvalid = true;
                });
        },
        populateDealers(data) {
            const $dealerSelect = this.fields.dealer;

            this.fetchDealers(data)
                .then(dealers => {
                    if (dealers && dealers.length > 0) {
                        // save dealers to later obtain
                        this.dealers = dealers;

                        // remove all options except for the first
                        $dealerSelect.find('option').not(':first').remove();

                        // populate select
                        this.dealers.forEach(({ Value, Text }) => {
                            $dealerSelect.append($('<option>', { value: Value, text: Text }));
                        });

                        if (this.selectedDealerCode) {
                            // we don't want to trigger the change when no dealer is selected
                            // because it will result in a (required) validation error for the select
                            $dealerSelect.change();
                        }
                    }
                });
        },
        getLatLng(zipCode, countryCode) {
            return new Promise((resolve, reject) => {
                return hereAPI.getGeoLocation(countryCode, null, zipCode)
                    .then(({ lat, lng }) => {
                        if (lat && lng) {
                            resolve({ lat, lng });
                        } else {
                            reject();
                        }
                    });
            });
        },
        getDealerByCode(code) {
            return this.dealers.find(dealer => dealer.Value === code);
        },
        fetchDealers(data) {
            return ajax.request({
                type: 'POST',
                url: `/${this.locale}/api/feature/forms/getdealers`,
                data: JSON.stringify(data)
            });
        },
        getDepartmentFields(departments) {
            return this.mapEmailEntries(departments, (department) => ({
                ...department,
                fieldName: `dealer_${department.code.toLowerCase()}`,
                fieldLabel: `Dealer Department - ${department.name}`,
            }));
        },
        getManagerFields(managers) {
            const formatLeadingZero = (digit) => {
                const digitAsInt = parseInt(digit, 10);
                return digitAsInt < 10 ? ('0' + digitAsInt) : digitAsInt;
            };

            return this.mapEmailEntries(managers, (manager, index) => ({
                ...manager,
                // manager codes can have multiple emails, so number field name and label
                fieldName: `dealer_${manager.code.toLowerCase()}_${formatLeadingZero(index + 1)}`,
                fieldLabel: `Dealer Function - ${manager.name} ${formatLeadingZero(index + 1)}`,
            }));
        },
        mapEmailEntries(entries, predicate) {
            const entriesGroupedByCode = lodash.groupBy(entries, 'Code');
            return Object.keys(entriesGroupedByCode).reduce((acc, code) => {
                const entries = entriesGroupedByCode[code];
                entries.forEach(({ Email, Name }, index) => {
                    if (Email) {
                        acc.push(
                            predicate({ code, email: Email, name: Name }, index)
                        );
                    }
                });
                return acc;
            }, []);
        },
        createMissingDealerCompanyProperties(personFields) {
            // if company properties for ddpd fields do not exist, we create them in HubSpot
            const missingCompanyProps = this.getMissingDealerCompanyProperties(personFields);

            if (missingCompanyProps.length > 0) {
                missingCompanyProps.forEach(({ fieldName, fieldLabel }) => {
                    this.createDealerCompanyProperty({
                        name: fieldName,
                        label: fieldLabel,
                    }).catch(error => {
                        console.error(error.message);
                    });
                });
            }
        },
        getMissingDealerCompanyProperties(ddpdFields) {
            if (this.dealerCompanyProperties.length === 0) {
                return [];
            }
            const compare = (ddpdField, dealerCompanyProp) => ddpdField.fieldName === dealerCompanyProp.name;
            return lodash.differenceWith(ddpdFields, this.dealerCompanyProperties, compare);
        },
        createDealerCompanyProperty({ name, label, description = '' }) {
            return HubspotService.createCompanyProperty(this.locale, {
                name,
                label,
                description,
                groupName: 'dealer_information',
                type: 'string',
                fieldType: 'text',
                formField: true,
            });
        },
        removeHiddenPropertyFields() {
            this.$form.find('[data-ddpd]').remove();
        },
        addHiddenCompanyField(data) {
            this.addHiddenPropertyField(data, HubspotPropertyType.COMPANY);
        },
        addHiddenContactField(data) {
            this.addHiddenPropertyField(data, HubspotPropertyType.CONTACT);
        },
        addHiddenPropertyField({ name, value, label }, propertyType) {
            const $field = HubspotFormsHelper.createCustomPropertyField({
                fieldType: 'hidden',
                name,
                value,
                label,
                propertyType,
                formId: this.formId,
                containerElementAttrs: { 'data-ddpd': '' }
            });
            $field.css('display', 'none');
            this.$form.append($field);
        },
    }
};
</script>