import Vue from 'vue';
import VueRouter from 'vue-router';
import SitecoreExpEditorPlugin from 'vue-scexpeditor';
import svg4everybody from 'svg4everybody';

// Vue components
import Catwalk from '@/Feature/Hotspot/code/src/components/Catwalk.vue';
import MobileLanguageSelector from '@/Feature/Language/code/Components/MobileLanguageSelector.vue';
import DaffcVocationals from '@/Feature/Vocationals/code/src/components/daffc-vocationals.vue';
import CookieConsentContainer from '@/Feature/CookieConsent/code/src/components/CookieConsentContainer.vue';
import Countdown from '@/Feature/Countdown/code/src/components/Countdown.vue';
import TruckFilterOverview from '@/Feature/TruckList/code/src/components/TruckFilterOverview.vue';
import Doorway from '@/Feature/Teasers/code/src/components/Doorway.vue';
import ContentCarousel from '@/Feature/Teasers/code/src/components/ContentCarousel.vue';
import GdprVideoplayer from '@/Feature/Media/code/src/components/GdprVideoplayer.vue';
import MainNavigationSlot from '@/Feature/Navigation/code/src/components/MainNavigationSlot.vue';
import HubspotForm from '@/Feature/Forms/code/src/components/HubspotForm.vue';
import HubspotFormWithDealers from '@/Feature/Forms/code/src/components/HubspotFormWithDealers.vue';
import Scrollytelling from '@/Feature/Scrollytelling/code/src/components/Scrollytelling.vue';
import HeaderVideo from '@/Feature/Header/code/src/components/HeaderVideo.vue';
import BaseVimeoPlayer from '@/Feature/Media/code/src/components/BaseVimeoPlayer.vue';
import Hotspot from '@/Feature/Hotspot/code/src/components/Hotspot.vue';
import ShowcaseGallery from '@/Feature/Teasers/code/src/components/ShowcaseGallery.vue';
import CertificateGenerator from '../../../../../Feature/CertificateGenerator/code/src/components/CertificateGenerator.vue';
import TabbedContent from '../../../../../Feature/TabbedContent/code/Sitecore.Feature.TabbedContent/src/components/TabbedContent.vue';

// Vuex store(s)
import store from './store.common.js';

// enable vue router
Vue.use(VueRouter);

// Sitecore Experience Editor Support fix
// By adding 'v-pre' attributes to Sitecore's <code> tags, we make Vue ignore them
// After that we use the SitecoreExpEditorPlugin to enable Exp Editor support for Vue
if (window.scEditMode === 'edit') {
    document.querySelectorAll('code[type="text/sitecore"]').forEach((el) => el.setAttribute('v-pre', ''));
    Vue.use(SitecoreExpEditorPlugin);
}

// disable vue production tip
Vue.config.productionTip = false;

// disable vue devtools
// Vue.config.devtools = false;

// eslint-disable-next-line

// create vue instance
new Vue({
    el: '#app',
    store,
    components: {
        Catwalk,
        MainNavigationSlot,
        DaffcVocationals,
        MobileLanguageSelector,
        CookieConsentContainer,
        Countdown,
        ContentCarousel,
        Doorway,
        GdprVideoplayer,
        HeaderVideo,
        HubspotForm,
        HubspotFormWithDealers,
        Scrollytelling,
        ShowcaseGallery,
        TruckFilterOverview,
        BaseVimeoPlayer,
        Hotspot,
        CertificateGenerator,
        TabbedContent,
    },
});

// init
$(window).ready(() => {
    // upgrade all registered components
    window.ComponentHandler.upgradeAllRegistered();
});

// init and enable svg spritesheets
svg4everybody();
