<template>
    <li class="text-left pl-1" data-dm="component.language-selector-mobile">
        <a
            id="navbarMobLanguageDropdown"
            class="nav-link dropdown-toggle"
            href="#"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            @click.prevent="toggleMenu"
        >
            {{ excludeMarketLanguage ? activeLanguage.DisplayName : activeLanguage.NativeName }}
            <span class="icon icon--chevron-right">
                <svg role="img" title="icon: chevron right">
                    <use href="/assets/icons/common/ui.svg#chevron-right" />
                </svg>
            </span>
        </a>
        <div v-if="isOpen" class="rounded-0 language-list" aria-labelledby="navbarMobLanguageDropdown">
            <a
                v-for="(language, index) in languages"
                :key="index"
                :href="language.Url"
                class="dropdown-item"
                :class="language.TwoLetterCode === activeLanguage.TwoLetterCode ? 'is-active' : ''"
                data-dm="language-selector-mobile.item"
                @click="() => SwitchToLanguage(language.Name, activeLanguage.Name, language.Url)"
            >
                {{ excludeMarketLanguage ? language.DisplayName : language.NativeName }}</a
            >
            }
        </div>
    </li>
</template>

<script>
export default {
    props: {
        excludeMarketLanguage: { type: Boolean, default: null },
        activeLanguage: { type: Object, default: null },
        languages: { type: Array, default: null }
    },
    data() {
        return {
            isOpen: false
        };
    },
    methods: {
        SwitchToLanguage(newLanguage, currentLanguage, redirectUrl) {
            const ajaxPost = (responseFunction, sender) => {
                const token = $(
                    '#_CRSFform input[name=__RequestVerificationToken]'
                ).val();
                $.ajax({
                    type: 'POST',
                    url: '/api/feature/language/changelanguage',
                    cache: false,
                    headers: { __RequestVerificationToken: token },
                    contentType: 'application/json; charset=utf-8',
                    data:
            '{"NewLanguage":"' +
            newLanguage +
            '", "CurrentLanguage":"' +
            currentLanguage +
            '"}',
                    success: function(data) {
                        if (responseFunction != null) {
                            responseFunction(data, true, sender);
                        }
                    },
                    error: function(data) {
                        if (responseFunction != null) {
                            responseFunction(data, false, sender);
                        }
                    }
                });
            };
            ajaxPost(function() {
                window.location = redirectUrl;
            });
            return false;
        },
        toggleMenu() {
            return this.isOpen = !this.isOpen;
        }
    }
};
</script>