<template>
    <div class="countdown-clock">
        <template v-for="timeUnit in timeUnits">
            <span
                v-show="timeUnit.isVisible"
                :ref="timeUnit.elementRef"
                :key="timeUnit.label"
                class="countdown-clock__piece"
            >
                <span class="countdown-clock__card countdown-card">
                    <b class="countdown-card__top" data-countdown-card-piece>
                        {{ timeUnit.current | leadingZero }}
                    </b>
                    <b
                        class="countdown-card__bottom"
                        data-countdown-card-piece
                        :data-value="timeUnit.current | leadingZero"
                    ></b>
                    <b
                        class="countdown-card__back"
                        data-countdown-card-piece
                        :data-value="timeUnit.previous | leadingZero"
                    ></b>
                    <b
                        class="countdown-card__back-bottom"
                        data-countdown-card-piece
                        :data-value="timeUnit.previous | leadingZero"
                    ></b>
                </span>
                <span class="countdown-clock__slot">{{ timeUnit.label }}</span>
            </span>
        </template>
    </div>
</template>

<script>
const DAYS_INDEX = 0;
const HOURS_INDEX = 1;
const MINUTES_INDEX = 2;
const SECONDS_INDEX = 3;

export default {
    name: 'Countdown',
    filters: {
        leadingZero(value) {
            if (value.toString().length <= 1) {
                return '0' + value.toString();
            }
            return value.toString();
        }
    },
    props: {
        targetDate: {
            type: String,
            required: true,
        },
        showDays: {
            type: Boolean,
            default: true
        },
        showHours: {
            type: Boolean,
            default: true
        },
        showMinutes: {
            type: Boolean,
            default: true
        },
        showSeconds: {
            type: Boolean,
            default: true
        },
        labels: {
            type: Object,
            required: false,
            default: () => ({
                days: 'Days',
                hours: 'Hours',
                minutes: 'Minutes',
                seconds: 'Seconds'
            })
        }
    },
    data() {
        return {
            now: 0,
            targetDatetime: 0,
            interval: null,
            timeDifference: 0,
            isFinished: false,
            timeUnits: [
                {
                    current: 0,
                    previous: 0,
                    label: this.labels.days,
                    elementRef: 'days',
                    isVisible: this.showDays
                },
                {
                    current: 0,
                    previous: 0,
                    label: this.labels.hours,
                    elementRef: 'hours',
                    isVisible: this.showHours
                },
                {
                    current: 0,
                    previous: 0,
                    label: this.labels.minutes,
                    elementRef: 'minutes',
                    isVisible: this.showMinutes
                },
                {
                    current: 0,
                    previous: 0,
                    label: this.labels.seconds,
                    elementRef: 'seconds',
                    isVisible: this.showSeconds
                }
            ]
        };
    },
    computed: {
        seconds() {
            return Math.trunc(this.timeDifference) % 60;
        },
        minutes() {
            return Math.trunc(this.timeDifference / 60) % 60;
        },
        hours() {
            return Math.trunc(this.timeDifference / 60 / 60) % 24;
        },
        days() {
            return Math.trunc(this.timeDifference / 60 / 60 / 24);
        }
    },
    watch: {
        now(newValue) {
            this.timeDifference = this.targetDatetime - newValue;
            if (this.timeDifference <= 0) {
                this.timeDifference = 0;
                this.updateTimeslot(SECONDS_INDEX, 0);
            } else {
                this.updateAllCards();
            }
        },
        timeDifference(newValue) {
            if (newValue === 0) {
                this.finish();
                this.updateAllCards();
            }
        }
    },
    created() {
        if (!this.targetDate) {
            throw new Error('Missing target date');
        }

        this.targetDatetime = Math.trunc(
            Date.parse(this.targetDate.replace(/-/g, '/')) / 1000
        );

        if (!this.targetDatetime) {
            throw new Error('Target date is invalid');
        }

        // initial countdown value
        this.now = Math.trunc(Date.now() / 1000);

        // initialize countdown interval
        this.interval = setInterval(() => {
            this.now = Math.trunc(Date.now() / 1000);
        }, 1000);
    },
    beforeDestroy() {
        if (window.cancelAnimationFrame) {
            cancelAnimationFrame(this.frame);
        }
    },
    destroyed() {
        clearInterval(this.interval);
    },
    methods: {
        updateAllCards() {
            this.updateTimeslot(DAYS_INDEX, this.days);
            this.updateTimeslot(HOURS_INDEX, this.hours);
            this.updateTimeslot(MINUTES_INDEX, this.minutes);
            this.updateTimeslot(SECONDS_INDEX, this.seconds);
        },
        updateTimeslot(index, newValue) {
            if (index >= this.timeUnits.length || newValue === undefined) {
                return;
            }

            if (window.requestAnimationFrame) {
                this.frame = requestAnimationFrame(this.updateTimeslot.bind(this));
            }

            const timeUnit = this.timeUnits[index];
            const timeUnitRef = this.$refs[timeUnit.elementRef] && this.$refs[timeUnit.elementRef][0];
            const value = newValue < 0 ? 0 : newValue;
            const hasValue = newValue / 1000 >= 1;

            if (value !== timeUnit.current) {
                timeUnit.previous = timeUnit.current;
                timeUnit.current = value;

                if (timeUnitRef) {
                    timeUnitRef.classList.remove('countdown');
                    void timeUnitRef.offsetWidth;
                    timeUnitRef.classList.add('countdown');
                }

                if (index === 0) {
                    const cardPieces = timeUnitRef.querySelectorAll('[data-countdown-card-piece]');
                    if (cardPieces) {
                        for (let cardPiece of cardPieces) {
                            const [firstCardPieceClass] = cardPiece.classList;
                            const hasFourDigits = firstCardPieceClass.includes('-4digits');

                            if (hasValue) {
                                if (!hasFourDigits) {
                                    const newCardPieceClass = firstCardPieceClass + '-4digits';
                                    cardPiece.classList.add(newCardPieceClass);
                                    cardPiece.classList.remove(firstCardPieceClass);
                                }
                            } else {
                                if (hasFourDigits) {
                                    const newCardPieceClass = firstCardPieceClass.replace('-4digits', '');
                                    cardPiece.classList.add(newCardPieceClass);
                                    cardPiece.classList.remove(firstCardPieceClass);
                                }
                            }
                        }
                    }
                }
            }
        },
        finish() {
            this.isFinished = true;
            this.$emit('finished');
            clearInterval(this.interval);
        }
    },
};
</script>