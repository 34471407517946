import Cookies from 'js-cookie';

const defaultProps = { expires: 365, secure: true };

const CookieHandler = {
    getCookie(name) {
        return Cookies.getJSON(name);
    },
    setCookie(name, value, options = {}) {
        const props = { ...defaultProps, ...options };
        if (!name && value !== false) return console.error('cookie name missing');
        if (!value && value !== false) return console.error('cookie value missing');
        return Cookies.set(name, value, props);
    },
};

export default CookieHandler;
