<template>
    <div class="hotspot-map">
        <div class="hotspot-map__container" :style="{ width: mapWidth }">
            <img
                v-if="image"
                class="hotspot-map__image"
                :alt="image.alt"
                :src="image.mw1920"
                :srcset="`${image.mw1920} 1920w, ${image.mw992} 992w`"
                sizes="(min-width: 993px) 1200px, (min-width: 769px) 992px"
                @load="onMapImageLoaded"
            />
            <template v-for="(hotspot, index) in hotspots">
                <button
                    :id="`hotspot-${index}`"
                    :key="`${hotspot.label} button`"
                    :style="{ top: `${hotspot.top}%`, left: `${hotspot.left}%` }"
                    :data-dm-element-value="`hotspot detail ${hotspot.label}`"
                    aria-label="Open detail view"
                    class="hotspot-button hotspot-map__point"
                    @click="selectHotspot(hotspot)"
                >
                    <span class="icon">
                        <svg role="img">
                            <use href="/assets/icons/common/ui.svg#plus"></use>
                        </svg>
                    </span>
                    <div class="hotspot-button__border"></div>
                </button>
                <label
                    :key="`${hotspot.label} label`"
                    :for="`hotspot-${index}`"
                    :style="{ top: `${hotspot.top}%`, left: `${hotspot.left}%` }"
                    class="hotspot-button__label"
                >
                    {{ hotspot.label }}
                </label>
            </template>
            <div class="hotspot-map__scroll-indicator" :class="{ 'has-scrolled': hasScrolledMap }">
                <svg role="img">
                    <use href="/assets/icons/common/ui.svg#mouse-pan"></use>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HotspotMap',
    props: {
        image: {
            type: Object,
            default: () => ({}),
        },
        hotspots: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            mapWidth: null,
            hasScrolledMap: false,
        };
    },
    mounted() {
    // update valtagmanager
        if (window.digitalData) {
            window.digitalData.update();
        }
    },
    methods: {
        selectHotspot(hotspot) {
            this.$emit('select', hotspot);
        },
        onMapImageLoaded(e) {
            const isMobile = viewport.is('<lg');
            if (isMobile) {
                // set map width based on map image ratio
                this.mapWidth = `${e.target.offsetWidth}px`;
                this.scrollMapHorizontally();

                // wait until the map is scrolled horizontally before appending scroll event
                setTimeout(() => {
                    this.checkIfMapIsScrolled();
                }, 250);
            }
        },
        scrollMapHorizontally() {
            const { offsetWidth, scrollWidth } = this.$el;
            this.$el.scrollLeft = (scrollWidth - offsetWidth) / 2;
        },
        checkIfMapIsScrolled() {
            // bind Vue context to use inside named function
            const context = this;
            const hotspotMap = this.$el;
            hotspotMap.addEventListener('scroll', function handleInitialScroll() {
                context.hasScrolledMap = true;
                // we just want to know if the map is scrolled at all, so we can remove the scroll indicator
                hotspotMap.removeEventListener('scroll', handleInitialScroll);
            });
        }
    }
};
</script>