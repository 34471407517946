<template>
    <div class="certificate-generator">
        <div class="input-wrapper">
            <label class="" for="vinNumber">{{ vinNumberText }}</label>

            <div class="search-block">
                <div class="search-block__input form-group position-relative">
                    <input
                        id="vinNumber"
                        v-model="vinNumber"
                        type="text"
                        :placeholder="vinNumberText"
                        class="form-control"
                        :class="{
                            'is-invalid': error,
                            'is-valid': successfullyAdded,
                        }"
                        @keyup.enter="checkPdf"
                        @input="clearInputTimeout"
                    />
                    <div v-if="successfullyAdded" class="valid-feedback feedback-icon">
                        <span class="icon mr-2">
                            <svg role="img">
                                <use href="/assets/icons/common/ui.svg#fa-checkmark"></use>
                            </svg>
                        </span>
                    </div>
                    <div v-if="error" class="invalid-feedback feedback-icon">
                        <span class="icon mr-2">
                            <svg role="img">
                                <use href="/assets/icons/common/ui.svg#error"></use>
                            </svg>
                        </span>
                    </div>
                </div>

                <div class="search-block__button">
                    <button
                        :disabled="vinNumber.length < 8 || loading"
                        class="btn btn-primary cert-btn btn-sm"
                        :class="loading ? 'cert-btn--loading' : ''"
                        type="button"
                        @click="checkPdf"
                    >
                        <span class="button__text">{{ btnGenerateText }}</span>
                    </button>
                </div>
            </div>

            <div class="help-text-block">
                <p>{{ vinNumberHelpText }}</p>
            </div>

            <div
                v-if="isResultsShown"
                class="status-field"
                :class="{ 'is-invalid': error, 'is-valid': successfullyAdded }"
            >
                <p v-if="loading">{{ loadingText }}</p>
                <p v-else-if="error">{{ errorMessage }}</p>
                <p v-else-if="successfullyAdded">{{ successMessage }}</p>
            </div>
        </div>

        <div class="downloads-section">
            <div class="downloads-section__title">{{ downloadsSectionHeader }}</div>
            <div class="titles">
                <div class="pdf-headers">
                    <div class="vin">{{ vinNumberText }}</div>
                    <div class="plate">{{ titleLicencePlate }}</div>
                </div>
                <button
                    v-if="false"
                    class="btn btn-outline-primary btn-sm title-download-all"
                    type="button"
                    @click="checkPdf"
                >
                    <span
                        v-if="loading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                    {{ downloadAllText }}
                </button>
            </div>
            <hr />
            <div class="certificates">
                <div v-for="(cert, idx) in pdfsArray" :key="idx">
                    <div class="certificate">
                        <div class="pdf-meta-info">
                            <div v-if="cert.vinRegistration" class="certificate__vin-number vin">
                                {{ getLastEightChars(cert.vinRegistration) }}
                            </div>
                            <div v-if="cert.registrationNumber" class="certificate__licence-plate plate">
                                {{ cert.registrationNumber }}
                            </div>
                        </div>

                        <div class="certificate__download">
                            <button
                                :ref="'downloadPdfButton' + idx"
                                class="btn btn-outline-primary btn-sm cert-btn"
                                type="button"
                                @click="downloadPdf(cert.vinRegistration, 'downloadPdfButton' + idx)"
                            >
                                <span class="icon download-icon mr-md-2">
                                    <svg role="img">
                                        <use href="/assets/icons/common/ui.svg#fa-download"></use>
                                    </svg>
                                </span>
                                <span class="button__text">{{ downloadPdfText }}</span>
                            </button>
                        </div>
                    </div>
                    <hr />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CertificateGenerator',

    props: {
        checkPdfApi: {
            type: String,
            required: true,
        },
        generatePdfApi: {
            type: String,
            required: true,
        },
        vinNumberText: {
            type: String,
            required: true,
        },
        btnGenerateText: {
            type: String,
            required: true,
        },
        vinNumberHelpText: {
            type: String,
            required: true,
        },
        successMessage: {
            type: String,
            required: true,
        },
        errorMessage: {
            type: String,
            required: true,
        },
        loadingText: {
            type: String,
            required: true,
        },
        downloadsSectionHeader: {
            type: String,
            required: true,
        },
        titleLicencePlate: {
            type: String,
            required: true,
        },
        downloadAllText: {
            type: String,
            required: true,
        },
        downloadPdfText: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            vinNumber: '',
            loading: false,
            error: false,
            successfullyAdded: false,
            pdfsArray: [],
            inputTimeoutId: null,
            resultBlockTimeoutId: null,
            timeoutDelay: 3000,
        };
    },

    computed: {
        isResultsShown() {
            return this.loading || this.error || this.successfullyAdded;
        },

        isGenerateButtonDisabled() {
            return !this.vinNumber || this.loading;
        },
    },

    methods: {
        clearInputTimeout() {
            clearTimeout(this.inputTimeoutId);
        },

        getLastEightChars(str) {
            return str.slice(-8);
        },

        hideResultsMessage() {
            clearTimeout(this.resultBlockTimeoutId);

            this.resultBlockTimeoutId = setTimeout(() => {
                this.successfullyAdded = false;
                this.error = false;
            }, this.timeoutDelay);

            this.inputTimeoutId = setTimeout(() => {
                this.vinNumber = '';
            }, this.timeoutDelay);
        },

        async checkPdf() {
            if (this.vinNumber.length < 8) return;

            this.loading = true;
            this.error = false;
            this.successfullyAdded = false;

            const response = await fetch(`${this.checkPdfApi}?vinRegistration=${this.vinNumber}`);

            const data = await response.json();

            if (data.result !== 'Found') {
                this.loading = false;
                this.error = true;
                this.hideResultsMessage();
                return;
            }

            if (data.londonDirectVisionStarRating) this.pdfsArray.push(data.londonDirectVisionStarRating);
            this.loading = false;
            this.successfullyAdded = true;
            this.hideResultsMessage();
        },

        async downloadPdf(vinNumber, refName) {
            if (this.$refs[refName][0]) {
                this.$refs[refName][0].classList.value += ' cert-btn--loading';

                // prevent double click on button while loading
                this.$refs[refName][0].disabled = true;
            }

            // get last 8 symbols from vin number
            const lastPartOfVin = this.getLastEightChars(vinNumber);

            try {
                const response = await fetch(`${this.generatePdfApi}?vinRegistration=${lastPartOfVin}`);
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${lastPartOfVin}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                this.$refs[refName][0].classList.value = this.$refs[refName][0].classList.value.replace(
                    'cert-btn--loading',
                    ''
                );

                this.$refs[refName][0].disabled = false;
            // eslint-disable-next-line no-unused-vars
            } catch (error) {
                this.$refs[refName][0].classList.value = this.$refs[refName][0].classList.value.replace(
                    'cert-btn--loading',
                    ''
                );
                this.$refs[refName][0].disabled = false;
            }
        },
    },
    // https://dafx-euw-tst-api-utilities.azurewebsites.net/LondonDirectVision/VinRegistrationExists?vinRegistration=0L511314
    // https://dafx-euw-dev-api-utilities.azurewebsites.net/LondonDirectVision/GeneratePdf?vinRegistration=0L511314
};
</script>
